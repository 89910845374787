<template>
  <div class="dash-body">
    <div class="dash-empty">
      <div class="alert" v-if="showPlanSuggestion">
        <p>
          {{ $t('INTRO_START_CURRENTLY_PLAN') }}
          <a href="#">{{ $t('INTRO_START_PRICING') }}</a>
        </p>
        <span class="close" @click="showPlanSuggestion = false"><i class="bi bi-x-lg"></i></span>
      </div>
      <img src="@/assets/images/image 61.svg" alt />
      <div class="welcome">
        <div class="b40">{{ $t('INTRO_START_EMPTY_LIST') }}</div>
        <div class="r16">{{ $t('INTRO_START_START_JOURNEY') }}</div>
        <button class="btn-st-red" @click="INTRO_SET_ADD_PAGE_VISIBLE(true)">
          {{ $t('INTRO_START_ADD_PAGE') }}
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { INTRO_SET_ADD_PAGE_VISIBLE } from "../../../store/actions/intro";
export default {
  name: "Intro-start",
  computed: {
  },
  methods: {
    ...mapMutations([INTRO_SET_ADD_PAGE_VISIBLE]),
  },

  data() {
    return {
      showPlanSuggestion: true,
    };
  },
};
</script>

<style>
</style>