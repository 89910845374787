<template>
  <tr @click="selectPage(page)" :class="classLine">
    <td>
      <input
        type="checkbox"
        name="checkbox"
        :id="page.hash_id"
        class="inp-chk"
        :checked="page.isSelected"
        @click.stop
      />
      <label for="m5" class="inp-chk"></label>
      <img :src="page.owner_avatar" alt class="list-logo" @click.prevent.stop="analyzePage(page)"/>
      <span @click.prevent.stop="analyzePage(page)" class="list-page-name">{{page.owner_name}}</span>
      <scroll-loader
        class="intro-line-loader"
        :loader-method="()=> {}"
        :loader-enable="page.status == 'added'"
      ></scroll-loader>
    </td>
    <td :class="classFolowers">{{formatNum(page.followers)}} <i v-if="iconVisible" :class="iconClass"></i></td>
    <td :class="classGrowth">{{formatNum(page.growth)}}</td>
    <td>{{formatNum(page.reach_avg)}}</td>
    <td>{{formatNum(page.er)}}</td>
    <td v-if="!social">
      <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
    </td>
    <td>
      <a target="_blank" :href="page.owner_url" class="ext-link" @click.stop>
        <i class="bi-box-arrow-up-right"></i>
      </a>
    </td>
    <td>
      <span v-if="page.status == 'finished'" class="close" @click.stop="deletePage(page)"><i class="bi-x-lg"></i></span>
      <span
        v-if="page.status == 'paused' || page.status == 'deleted'"
        class="close"
        @click.stop="restorePage(page)"
      ><i class="bi-x-lg"></i></span>
    </td>
  </tr>
</template>

<script>
import pageUtils from "@/models/page-utils";

import { WALL_HASH } from "../../../store/actions/wall";
import {
  INTRO_SET_SELECTED,
  INTRO_UPDATE_ADDED,
  INTRO_SET_DELETED,
  INTRO_DROP_SELECTED
} from "../../../store/actions/intro";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "intro-line",
  components: {},
  props: {
    page: {
      type: Object,
      required: true,
    },
    social: {
      type: String,
    },
  },
  // watch: {
  //   page: {
  //     handler(newValue) {},
  //     deep: true
  //   }
  // },
  computed: {
    ...mapGetters(["getPeriod"]),
    classFolowers() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth > 0 ? 'positive' : intGrowth < 0 ? 'negative' : '';
      return "";
    },
    classGrowth() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth > 0 ? 'grw-pos' : intGrowth < 0 ? 'grw-neg' : '';
      return "";
    },
    iconVisible() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth !== 0;
    },
    iconClass() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth > 0 ? 'bi bi-arrow-up-right' : 'bi bi-arrow-down-right';
    },
    classLine() {
      if (this.page.status == "finished") return "";
      return "inactive";
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations([INTRO_UPDATE_ADDED, INTRO_SET_DELETED, INTRO_DROP_SELECTED]),
    ...mapActions([INTRO_SET_SELECTED]),
    formatNum(num) {
      return pageUtils.formatNumbers(num);
    },
    analyzePage(page) {
      this.INTRO_DROP_SELECTED()
      this.INTRO_SET_SELECTED(page)
      this.$router.push({ path: '/dashboard' })

    },
    selectPage: function (page) {
      if (page.status != "finished") return;
      this.INTRO_SET_SELECTED(page);
    },
    async deletePage({ hash_id }) {
      await this.$Axios.post("delete-page", { hash_id });
      this.INTRO_SET_DELETED(hash_id);
    },
    restorePage(page) {
      const added = [];
      const toRestore = {
        hash_id: page.hash_id,
        is_admin: page.is_admin,
        owner_avatar: page.owner_avatar,
        owner_domain: page.owner_domain,
        owner_avatar: page.owner_avatar,
        owner_id: page.owner_id,
        owner_name: page.owner_name,
        owner_type: page.owner_type,
        owner_url: page.owner_url,
        social: page.social,
      };
      this.INTRO_UPDATE_ADDED(page.hash_id);

      this.$Axios.post("add-page", {
        pages: [toRestore],
        owner_email: page.email,
      });
    },
  },
};
</script>

