<template>
  <div>
    <Header :showInput="true" />
    <Intro-body />
    <Footer v-if="getSelectedPages.length" />
    <div v-if="authProcessing" class="start-window">
      <div class="b21">{{ $t('USR_SAV') }}...</div>
    </div>
  </div>
</template>

<script>
import { AUTH_SOCIAL_TOKENS } from "@/store/actions/auth";
import { ADMIN_SET } from "../../store/actions/admin";
import {
  INTRO_SET_PERIOD,
  INTRO_SET_PAGES,
  INTRO_DROP_SELECTED,
  INTRO_SET_SELECTED_ACCESS,
} from "../../store/actions/intro";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import IntroBody from "./components/Intro-body";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Intro",
  components: {
    Header,
    IntroBody,
    Footer,
  },
  data() {
    return {
      authProcessing: false,
    };
  },
  computed: {
    ...mapGetters([
      "getPeriod",
      "getPages",
      "getProfile",
      "getAllowedAccesses",
      "getSelectedPages",
      "isPagesLoaded",
      "getAddedHashes",
    ]),
    locale() {
      return this.$locale.INTRO.NOTIFICATIONS;
    },
    countPagesInAnalize() {
      return this.getAddedHashes.length;
    },
  },
  watch: {
    countPagesInAnalize(newCount, oldCount) {
      if (oldCount > newCount) {
        let title = document.title;
        let interval = setInterval(() => {
          const notification = this.locale.NOTIFICATION_TITLE; //*Archi
          if (document.title === notification) document.title = title;
          else document.title = notification;
        }, 700);
        setTimeout(() => {
          clearInterval(interval);
          document.title = title;
        }, 5000);
      }
    },
  },
  methods: {
    ...mapActions([INTRO_SET_PAGES, AUTH_SOCIAL_TOKENS]),
    ...mapMutations([
      INTRO_SET_PERIOD,
      INTRO_DROP_SELECTED,
      INTRO_SET_SELECTED_ACCESS,
    ]),
    setStartedPeriod() {
      if (!this.getPeriod) {
        const period = {
          start: moment().subtract(7, "days").format("YYYY-MM-DD"),
          end: moment().subtract(1, "days").format("YYYY-MM-DD"),
        };
        this.INTRO_SET_PERIOD(period);
      }
    },
    isVkPageTokens(url) {
      const match = {};
      [...url.matchAll(/access_token_([0-9]*)=([0-9a-zA-Z._-]*)/gm)].forEach((i) => {
        const [_, key, value] = i;
        match[key] = value;
      });
      if (Object.keys(match).length) return match;
      return undefined;
    },
    isVk(url) {
      const match = url.match(
        "access_token=([-A-Za-z0-9._]*)&expires_in=([0-9]*)&user_id=([0-9]*)"
      );
      if (match && match.length === 4) return { vk_token: match[1] };
      return false;
    },
    isFb(url) {
      const match = url.match(
        `access_token=([A-Za-z0-9]*)&([\\S]*)&state=(facebook)`
      );
      if (match && match.length === 4) return { fb_token: match[1] };
      return false;
    },
    isIg(url) {
      const match = url.match(
        "access_token=([A-Za-z0-9]*)&([\\S]*)&state=(instagram)"
      );
      if (match && match.length === 4) return { ig_token: match[1] };
      return false;
    },
    saveSocialTokens(url) {
      const token = this.isFb(url) || this.isVk(url) || this.isIg(url);
      if (token) {
        this.authProcessing = true;
        this.$Axios.post("social-token", { token }).then((response) => {
          this.$store.dispatch(AUTH_SOCIAL_TOKENS);
          localStorage.setItem(
            "message",
            JSON.stringify({ command: "auth", data: token })
          );
          this.authProcessing = false;
          this.$router.push('/main');
        });
      }
    },
    saveVkPageTokens(tokens) {
      this.authProcessing = true;
      this.$Axios.post("vk-page-tokens", { tokens }).then(() => {
        this.authProcessing = false;
        this.$router.push('/main');
      });
    },
    parseToken() {
      const url = window.location.href;
      if (url.includes('access_token') || url.includes('state')) {
        const pageTokens = this.isVkPageTokens(url);
  
        if (pageTokens) {
          this.saveVkPageTokens(pageTokens);
        } else {
          this.saveSocialTokens(url);
        }
      }
    },
  },
  async created() {
    if (!this.$store.getters.isAccessLoad) {
      await this.$store.dispatch(ADMIN_SET);
    }
    this.INTRO_SET_SELECTED_ACCESS([...this.getAllowedAccesses]);
  },
  async mounted() {
    this.setStartedPeriod();
    this.INTRO_DROP_SELECTED();
    if (!this.isPagesLoaded) {
      this.INTRO_SET_PAGES();
    }
    this.parseToken();
  },
};
</script>
