<template>
  <div>
    <div class="dash-body">

      <div class="alert al-fixed" v-if="getAlertStatus">
        <p>{{ getAlertText }}</p>
        <span
          class="close"
          @click.stop="INTRO_SET_ALERT({ status: false, text: '' })"
          ><i class="bi bi-x-lg"></i></span
        >
      </div>

      <div class="dash-header">
        <div class="dash-caption">
          {{ $t('INTRO_MAIN_SOCIAL_MEDIA') }}
          <button class="add-plus" @click="INTRO_SET_ADD_PAGE_VISIBLE(true)">
            +
          </button>
        </div>
        <div class="dash-options">
          <div
            class="drpdown displaying"
            v-click-outside="
              () => {
                isDisplayingBy = false;
              }
            "
          >
            <input type="checkbox" name id="z2" v-model="isDisplayingBy" />
            <label for="z2">
              <b>{{ $t('INTRO_MAIN_DISPLAYING_BY') }}</b>
              {{ $t(currentFilterKey) }}
            </label>
            <span class="expand"></span>
            <ul>
              <li
                v-if="currentFilter !== groupByName"
                @click.stop="setFilter(groupByName)"
              >
                {{ $t('INTRO_MAIN_BY_NAME') }}
              </li>
              <li
                v-if="currentFilter !== groupBySocial"
                @click.stop="setFilter(groupBySocial)"
              >
                {{ $t('INTRO_MAIN_BY_SOCIAL') }}
              </li>
              <li
                v-if="currentFilter !== groupByOwner && getAllowedAccesses.length > 1"
                @click.stop="setFilter(groupByOwner)"
              >
                {{ $t('INTRO_MAIN_BY_OWNER') }}
              </li>
            </ul>
          </div>

          <calendar
            :callback="INTRO_SET_PAGES"
            :disabledDates="getDisabledDates"
          />
          <report-button />
        </div>
      </div>
      <change-token-alert/>
      <!-- <div>
        <p>{{ $t('INTRO_START_CURRENLY_PLAN') }}</p>
      </div> -->

      <div v-if="currentFilter === groupBySocial">
        <intro-list
          v-if="getPagesBySocial['FB'].length"
          :pages="getPagesBySocial['FB']"
          social="fb"
          socialName="Facebook"
        ></intro-list>
        <intro-list
          v-if="getPagesBySocial['IG'].length"
          :pages="getPagesBySocial['IG']"
          social="ig"
          socialName="Instagram"
        ></intro-list>
        <intro-list
          v-if="getPagesBySocial['VK'].length"
          :pages="getPagesBySocial['VK']"
          social="vk"
          socialName="VK"
        ></intro-list>
      </div>
      <div v-if="currentFilter === groupByName">
        <intro-list :pages="getPagesByName"></intro-list>
      </div>
      <div v-if="currentFilter === groupByOwner">
        <intro-list
          :key="index"
          :email="owner.email"
          :pages="owner.pages"
          v-for="(owner, index) in getPagesByOwners"
        ></intro-list>
      </div>
      <div v-if="getDeletedPages.length">
        <span class="deleted-list">
          {{ $t('DASHBOARD_DELETED') }}:
        </span>
        <intro-list
          :key="index"
          :email="owner.email"
          :pages="owner.pages"
          v-for="(owner, index) in getDeletedPagesByOwners"
        ></intro-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  INTRO_SET_ADD_PAGE_VISIBLE,
  INTRO_SET_FILTER,
  INTRO_SET_SELECTED,
  INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE,
  INTRO_SET_PAGES,
  INTRO_UPDATE_STATUS_PAGES,
  INTRO_SET_ALERT,
} from "../../../store/actions/intro";
import IntroList from "./Intro-list";
import Calendar from "../../../components/Calendar";
import ReportButton from "../../../components/ReportButton";
import ChangeTokenAlert from "@/components/ChangeTokenAlert";
import ClickOutside from "vue-click-outside";

export default {
  name: "Intro-main",
  components: {
    IntroList,
    ReportButton,
    Calendar,
    ChangeTokenAlert,
  },
  computed: {
    ...mapGetters([
      "getIntroFilterType",
      "getPeriod",
      "getAddedHashes",
      "getSelectedPages",
      "getPagesByName",
      "getPagesBySocial",
      "getAlertStatus",
      "getAlertText",
      "getPagesByOwners",
      "getAllowedAccesses",
      "getDisabledDates",
      "getDeletedPages",
      "getDeletedPagesByOwners",
    ]),
    currentFilterKey() {
      if (this.currentFilter === "By Name") {
        return 'INTRO_MAIN_BY_NAME';
      } else if (this.currentFilter === "By Social") {
        return 'INTRO_MAIN_BY_SOCIAL';
      } else if (this.currentFilter === "By Owner") {
        return 'INTRO_MAIN_BY_OWNER';
      }
    }
  },
  methods: {
    ...mapActions([INTRO_SET_PAGES, INTRO_UPDATE_STATUS_PAGES]),
    ...mapMutations([
      INTRO_SET_ADD_PAGE_VISIBLE,
      INTRO_SET_FILTER,
      INTRO_SET_SELECTED,
      INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE,
      INTRO_SET_ALERT,
    ]),
    updatePages() {
      setInterval(() => {
        if (!this.getAddedHashes.length) return;
        this.INTRO_UPDATE_STATUS_PAGES();
      }, 5000);
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    setFilter(filter) {
      this.currentFilter = filter;
      this.isDisplayingBy = false;
    }
  },
  mounted() {
    this.popupItem = document.getElementsByClassName("displaying")[0];
    this.updatePages();
  },
  data() {
    return {
      isDisplayingBy: false,
      groupByName: "By Name",
      groupBySocial: "By Social",
      groupByOwner: "By Owner",
      currentFilter: "By Name",
    };
  },
  directives: {
    ClickOutside,
  },
};
</script>


<style></style>
